<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="star"></b-icon>
          <span>
            {{ $ml.with(strategy.name).get('bets_strategy_title') }}
          </span>
        </h4>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="mode" width="50" :label="$ml.get('bets_mode')" sortable searchable v-slot="props">
            {{ props.row.mode }}
          </b-table-column>
          <b-table-column field="bookmaker" width="150" :label="$ml.get('bets_bookmaker')" class="rightal" sortable searchable
                          v-slot="props">
            <Bk :bk="props.row.bookmaker"/>
          </b-table-column>
          <b-table-column field="count" width="50" :label="$ml.get('bets_count')" sortable searchable v-slot="props">
            {{ props.row.count }}
          </b-table-column>
          <b-table-column width="50" field="coef" :label="$ml.get('bets_coef')" sortable searchable v-slot="props">
            {{ props.row.coef }}
          </b-table-column>
          <b-table-column width="50" field="stake" :label="$ml.get('bets_stake')" sortable searchable v-slot="props">
            <Money v-bind:currency="props.row.currency" v-bind:amount="props.row.stake"/>
          </b-table-column>
          <b-table-column field="timeValue" width="100" :label="$ml.get('bets_time')" class="leftal" sortable searchable v-slot="props">
            {{ props.row.timeValue }}
          </b-table-column>
          <b-table-column field="event" :label="$ml.get('bets_event_line')" sortable searchable v-slot="props">
            <div class="columns">
              <div class="column is-7 rightal">
                <span>{{ props.row.homeTeam }} - {{ props.row.awayTeam }}</span>
              </div>
              <div class="column is-1 has-text-centered">
                <Sport v-bind:sport="props.row.sport"/>
              </div>
              <div class="column is-4 leftal">
                <BkLine :market="props.row.market" :target="props.row.target" :pivot="props.row.pivot"/>
              </div>
            </div>
          </b-table-column>
          <b-table-column field="placedTime" width="60" :label="$ml.get('bets_placed')" sortable v-slot="props">
            {{ props.row.placedTime_hr }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import State from "@/components/Status";
import Money from "@/components/Money";
import Vue from "vue";
import Sport from "@/components/Sport";
import BkLine from "@/components/BkLine";
import Bk from "@/components/Bk";

export default {
  name: "Strategy",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: false,
      items: [],
      strategy: {name: ''},
    }
  },
  components: {Sport, Bk, BkLine, State, Money},
  computed: {},
  methods: {
    retrieve(id) {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `bets/strategy?id=${id}`).then(resp => {
        this.items = resp.data.result.items
        this.strategy = resp.data.result.strategy
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    let id = this.$route.query.id
    this.retrieve(id)
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_bets_strategy'),
    }
  }
}
</script>

<style scoped>
</style>